/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Text, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, Subtitle, SeparateLine, SeparateLineWrap, Image, Title, YouTube, ContactForm, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"} fullscreen={false}>
          
          <Column className="--menu --style5 pb--08 pt--08" menu={true} fullscreen={false}>
            
            <Menu fullscreen={false}>
              
              <ColumnWrapper className="menu-logo-box pl--08" style={{"paddingLeft":null}} fullscreen={false}>
                
                <Text className="text-box fs--30 text-box--invert ls--002 lh--1" content={"<span style=\"font-weight: bold;\">Bagr domů</span><br>"} fullscreen={false}>
                </Text>

                <Text className="text-box fs--24 mt--0" style={{"color":"var(--color-variable-1)"}} content={"<span style=\"color: rgb(255, 255, 255); font-weight: bold;\">Dnešice Černotín 11 Plzeň-jih</span><br>"} fullscreen={false}>
                </Text>

              </ColumnWrapper>

              <MenuWrap fullscreen={false}>
                
                <MenuButton fullscreen={false}>
                </MenuButton>

                <MenuWrapBox fullscreen={false}>
                  
                  <MenuButtonClose fullscreen={false}>
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--16" href={"#fotogalerie"} target={""} content={"Fotogalerie"} fullscreen={false}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--16" href={"#kontakt"} target={""} content={"Kontakt"} fullscreen={false}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--16" href={"#kontakt"} target={""} content={"Rezervace"} fullscreen={false}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-vvolvl --style3 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13064/6692c7572924425abd8e9dc264da6625_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--invert" content={"<br>"}>
              </Text>

              <TitleMain className="title-box fs--102 title-box--invert swpf--uppercase mt--0" style={{"marginTop":null}} content={"BAGR DOMŮ<br>Zemní práce Plzeňsko<br>"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"sluzby"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--shadow2 fs--36" content={"Hledáte efektivní a spolehlivou realizaci zemních prací?<br>Půjčíme Vám jednoduše malý bagr Kubota,&nbsp;<br>nebo vykopeme Vaši zakázku špičkovými stroji s obsluhou.<br><br>"}>
              </Subtitle>

              <Text className="text-box fs--24" style={{"maxWidth":800}} content={"<span style=\"font-weight: bold; color: rgb(74, 74, 74);\">&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovaci-cara"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--10" name={"cenik"} layout={"l4"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--30" style={{"maxWidth":800}} content={"<span style=\"font-weight: bold; color: rgb(69, 65, 65);\"><br><br>Půjčovna malých bagrů Kubota ZDE:</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape4 btn-box--cColor2 btn-box--shadow5" href={"www.kubotadomu.cz"} content={"Půjčovna Kubota<br>Vstupte<br>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--2 fs--86 w--500 swpf--uppercase ls--04 lh--18" style={{"marginBottom":43,"paddingBottom":13.59375}} content={"<span style=\"color: rgb(0, 7, 78); font-weight: bold;\">Realizační technika</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=860x_.jpeg 860w"} position={{"x":"-45.83657587548638%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"JCB3CX sitemaster<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Klasický traktorbagr 8 t<br>joystick control<br>lžíce 20,30,40,50,60, <br>Naklápěcí hydro 150 cm<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/050d484dec83436c85837f2308d41f54_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/050d484dec83436c85837f2308d41f54_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/050d484dec83436c85837f2308d41f54_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/050d484dec83436c85837f2308d41f54_s=860x_.jpeg 860w"} position={{"x":"-73.00778210116732%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Kubota U50-5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 5t<br>Lžíce 30,40,50,150 cm<br>Naklápěcí hlava<br><br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/ff95ed3df43349d8b413016c58998362_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/ff95ed3df43349d8b413016c58998362_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/ff95ed3df43349d8b413016c58998362_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/ff95ed3df43349d8b413016c58998362_s=860x_.jpeg 860w"} position={{"x":"-14.00778210116732%","y":"-0.38910505836575876%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Kubota U27-4"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo &nbsp;2,7t<br>Lžíce 40,60,100 cm<br>Naklápěcí hlava<br>Nulový přesah zádě<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" style={{"maxWidth":426}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/278fed94861e4633b56fdb566542ed28_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":200}} content={"Kubota U10-5<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 1.1 t<br>Lžíce 20,30,40,<br>Naklápěcí hydrosvahovka <br>80 cm<br>Nulový přesah zádě<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/d89af56dff304e2e804e38b8b55e75ae_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/d89af56dff304e2e804e38b8b55e75ae_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/d89af56dff304e2e804e38b8b55e75ae_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/d89af56dff304e2e804e38b8b55e75ae_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/d89af56dff304e2e804e38b8b55e75ae_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13064/d89af56dff304e2e804e38b8b55e75ae_s=2000x_.jpeg 2000w"} position={{"x":"-14.00778210116732%","y":"-0.38910505836575876%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Kubota U17-3"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Bezpřesahové minirypadlo\n1650 kg<br>Průjezd 99 cm<br>Lžíce 20,30,40,50,100 cm<br>Naklápěcí hlava<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" style={{"maxWidth":426}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Kubota KX019-4<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 1850 kg<br>Průjezd 99 cm\n<br>Lžíce 30,40,50,100 cm<br>Naklápěcí hlava<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/705f6d186f21494d965154b076084712_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/705f6d186f21494d965154b076084712_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/705f6d186f21494d965154b076084712_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/705f6d186f21494d965154b076084712_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/705f6d186f21494d965154b076084712_s=1400x_.jpeg 1400w"} position={{"x":"-14.00778210116732%","y":"-0.38910505836575876%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Kubota KX037-4"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 3590 kg<br>Průjezd 99 cm<br>Lžíce 20,30,40,50,100 cm<br><br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" style={{"maxWidth":426}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/f1c52a7d2db14daebe3e66b2f84003fe_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Kubota KX018-4<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Minirypadlo 1790 kg<br>Průjezd 99 cm\n<br>Lžíce 30,40,50,100 cm<br><br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/f19131ad12b2435d975322c89c4995f2_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/f19131ad12b2435d975322c89c4995f2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/f19131ad12b2435d975322c89c4995f2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/f19131ad12b2435d975322c89c4995f2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/f19131ad12b2435d975322c89c4995f2_s=1400x_.jpg 1400w"} position={{"x":"-14.00778210116732%","y":"-0.38910505836575876%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Kubota K008-5"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<br>Minirypadlo 1 t<br>Lžíce 20,40 80 cm<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" style={{"maxWidth":426}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/45eaf2b6dfbd4c22931123bdb01faf8c_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/45eaf2b6dfbd4c22931123bdb01faf8c_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/45eaf2b6dfbd4c22931123bdb01faf8c_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/45eaf2b6dfbd4c22931123bdb01faf8c_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/45eaf2b6dfbd4c22931123bdb01faf8c_s=1400x_.jpeg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Nakladač Kubota RO70<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Kloubový nakladač 4,520 t<br>Lopata+vidle<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/7cc787bbd58b46fbb27eea7777e794a3_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/7cc787bbd58b46fbb27eea7777e794a3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/7cc787bbd58b46fbb27eea7777e794a3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/7cc787bbd58b46fbb27eea7777e794a3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/7cc787bbd58b46fbb27eea7777e794a3_s=1400x_.jpg 1400w"} position={{"x":"-14.00778210116732%","y":"-0.38910505836575876%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Dumper Thwaites 1,5 t"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Výsuvná a otočná korba<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" style={{"maxWidth":426}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/7a84e03469684802ad4b972706bd747a_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/7a84e03469684802ad4b972706bd747a_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/7a84e03469684802ad4b972706bd747a_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/7a84e03469684802ad4b972706bd747a_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/7a84e03469684802ad4b972706bd747a_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13064/7a84e03469684802ad4b972706bd747a_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--22" style={{"maxWidth":200}} content={"Dumper Thwaites 3 t<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Výsuvná otočná korba<br>"}>
              </Text>

              <Subtitle className="subtitle-box w--500 ls--001" content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"grbmm5zndkl"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/ee6ac595a37f4263b9e44d06131106b0_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/ee6ac595a37f4263b9e44d06131106b0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/ee6ac595a37f4263b9e44d06131106b0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/ee6ac595a37f4263b9e44d06131106b0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/ee6ac595a37f4263b9e44d06131106b0_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(4, 2, 2);\"><span style=\"caret-color: rgb(0, 0, 0);\">Bobcat S530<br>Paletové vidle<br></span>Hladká nebo prosévací lopata Allu</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/e461c0f20e944ebca1ba16c63fe27039_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/e461c0f20e944ebca1ba16c63fe27039_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/e461c0f20e944ebca1ba16c63fe27039_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/e461c0f20e944ebca1ba16c63fe27039_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: rgb(0, 0, 0);\">Minidumpery Cormidi 14.85<br>Minidumper Cormidi 1600<br>Vyvážení vykopaného materiálu z těžce dostupných míst<br></span><span style=\"color: rgb(17, 16, 16);\">Možno nasadit i více kusů pro rychlejší průběh práce</span></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5 pb--08 pl--06 pr--06 pt--16" src={"https://cdn.swbpg.com/t/13064/80cbcf184c1f47ec9b0ade8408249861_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":422}} srcSet={"https://cdn.swbpg.com/t/13064/80cbcf184c1f47ec9b0ade8408249861_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/80cbcf184c1f47ec9b0ade8408249861_s=660x_.jpg 660w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(12, 0, 0);\"><span style=\"font-weight: bold;\">Vibrační ježek Bomag BMP 8500<br>Hutnění velkých ploch</span></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"x56t0sy0ead"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/355a60b3890546e9b60d330756485919_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/355a60b3890546e9b60d330756485919_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/355a60b3890546e9b60d330756485919_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/355a60b3890546e9b60d330756485919_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(10, 10, 10);\">DAF &nbsp;LF 45.220 kontejnerová nástavba&nbsp;<br>celková 12 tun<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/1c9f2b15c9fc4d28813057aa928d7102_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/1c9f2b15c9fc4d28813057aa928d7102_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/1c9f2b15c9fc4d28813057aa928d7102_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/1c9f2b15c9fc4d28813057aa928d7102_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(7, 3, 3); font-weight: bold;\"><span style=\"caret-color: rgb(10, 10, 10);\">Augertorque vrtací zařízení k&nbsp;minibagru<br></span>Ploty,sloupky,otvory<br>Vrtáky 100-150-200-250-300 mm</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/fa05592411e242bfaf3defd6e1956a52_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/fa05592411e242bfaf3defd6e1956a52_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/fa05592411e242bfaf3defd6e1956a52_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/fa05592411e242bfaf3defd6e1956a52_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 18, 18); font-weight: bold;\">Kladivo Montabert SC8 pro Kubotu K008-3<br>Menší bourací práce včetně interiéru&nbsp;<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/76235deef18f45ccb021a6000010d6af_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/76235deef18f45ccb021a6000010d6af_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/76235deef18f45ccb021a6000010d6af_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/76235deef18f45ccb021a6000010d6af_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(10, 10, 10);\">Obousměrná vibrační deska Bomag <br>200 kg<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/17548eb374fb482cbcd1a78d08f56342_s=660x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/17548eb374fb482cbcd1a78d08f56342_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/17548eb374fb482cbcd1a78d08f56342_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/17548eb374fb482cbcd1a78d08f56342_s=860x_.jpeg 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"caret-color: rgb(10, 10, 10); color: rgb(7, 3, 3); font-weight: 700;\">Malá deska Bomag BR95<br></span><span style=\"color: rgb(19, 1, 1); font-weight: bold;\">jednosměrná 92 kg</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13064/17830149acfe4ddcae09728528182100_s=860x_.jpeg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/13064/17830149acfe4ddcae09728528182100_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/17830149acfe4ddcae09728528182100_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/17830149acfe4ddcae09728528182100_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/17830149acfe4ddcae09728528182100_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13064/17830149acfe4ddcae09728528182100_s=2000x_.jpeg 2000w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"color: rgb(19, 18, 18); font-weight: bold;\">Jednosměrná vibrační deska Husqvarna 112 kg<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wz5p419tl5a"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Půjčovna minibagru a nejoblíbenější stroj U10-5"}>
              </Title>

              <Text className="text-box" content={"<span style=\"font-weight: bold; color: rgb(12, 12, 12);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"-x_i-Mps5ng"} autoplay={true} loop={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"oddelovaci-cara"} layout={"l6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--l8w pb--30 pt--30" name={"fotogalerie"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Fotogalerie"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13064/d11ed1da882f42b096cbffb3b4a8d57f_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/d11ed1da882f42b096cbffb3b4a8d57f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/d11ed1da882f42b096cbffb3b4a8d57f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/d11ed1da882f42b096cbffb3b4a8d57f_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13064/8ed7ae37c5a04dc79b266b7604a2010e_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/8ed7ae37c5a04dc79b266b7604a2010e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/8ed7ae37c5a04dc79b266b7604a2010e_s=660x_.jpeg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/effbd050946f48849aeb7793b7f5a53d_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13064/76e36108837d4a16939bc0298f6970e4_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/76e36108837d4a16939bc0298f6970e4_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/76e36108837d4a16939bc0298f6970e4_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/76e36108837d4a16939bc0298f6970e4_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13064/c2e0f4328d9043fc84b762dce27b150b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/c2e0f4328d9043fc84b762dce27b150b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13064/c2e0f4328d9043fc84b762dce27b150b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13064/c2e0f4328d9043fc84b762dce27b150b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13064/c2e0f4328d9043fc84b762dce27b150b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13064/c2e0f4328d9043fc84b762dce27b150b_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13064/6ab0ae3e0770403f8cd8c7f9b1d2a2e0_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13064/6ab0ae3e0770403f8cd8c7f9b1d2a2e0_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/13064/6ab0ae3e0770403f8cd8c7f9b1d2a2e0_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/13064/6ab0ae3e0770403f8cd8c7f9b1d2a2e0_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/13064/6ab0ae3e0770403f8cd8c7f9b1d2a2e0_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/13064/6ab0ae3e0770403f8cd8c7f9b1d2a2e0_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ajl5uzzfcnh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--shape4 --shadow5">
              
              <Title className="title-box" content={"Poptávkový &nbsp;formulář"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Na Vaši zaslanou poptávku budeme reagovat během dne,<br>případně volejte tel. 601 291 730<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape4 --style2 --shadow5 w--700 lh--18 pb--25" style={{"maxWidth":880}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5amw7mq59di"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" style={{"color":"var(--color-variable-1)"}} content={"<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"kontakt"} layout={"l2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Rezervace"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/email-flat-1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--02" content={"michal@prusik.cz"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/phone-flat-1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--02" content={"+420 601 291 730"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/o/i/moduls/adress-flat-1.svg"} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":60}} svgColor={"rgba(39,39,39,1)"}>
              </Image>

              <Subtitle className="subtitle-box mt--02" content={"Černotín 11 Dnešice Plzeň-jih<br>"}>
              </Subtitle>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover place={"Černotín 11 Dnešice"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}